import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import store from '@/state/store.js';
import VueApexCharts from 'vue-apexcharts';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Industries reports',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  components: {
    apexcharts: VueApexCharts
  },
  data() {
    return {
      loading: false,
      colors: JSON.parse(localStorage.getItem('FinancialReportingColors')) || ['#FF5733', '#D0A9F5', '#2ECC71', '#5DADE2', '#F5B041']
    };
  },
  methods: {
    updateChart() {
      this.chartOptions.colors = this.colors.slice(0, this.industryReporting.length);
      this.chartSeries = this.industryReporting.map((item, index) => ({
        name: `Item ${index + 1}`,
        data: [Math.floor(Math.random() * 100)]
      }));
      setTimeout(() => {
        ApexCharts.exec('chart', 'updateOptions', this.chartOptions);
        ApexCharts.exec('chart', 'updateSeries', this.chartSeries);
      }, 500);
    },
    generateColors(count) {
      const colors = [];
      for (let i = 0; i < count; i++) {
        colors.push(this.generateColor());
      }
      return colors;
    },
    generateColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    industryDetails(industry) {
      let {
        id,
        individual
      } = industry;
      if (!individual.length) {
        this.$notify({
          title: 'Message',
          message: 'No Users available'
        });
        return;
      }
      this.$router.push(`/admin/reporting/industries/${id}/users`).catch(err => {});
    },
    industryColor(industryname) {
      let color = this.colors.find((color, index) => {
        return industryname == this.industryLabels[index];
      });
      return color;
    },
    industryPercentage(usersLength) {
      let percentage = 0;
      if (this.industryUsersCount == 0) return percentage;
      percentage = usersLength / this.industryUsersCount * 100;
      return `${percentage.toFixed(2)}%`;
    }
  },
  computed: {
    industryReporting() {
      return store.getters['admin/reporting/industryReporting'];
    },
    industryUsersCount() {
      return store.getters['admin/reporting/industryUsersCount'];
    },
    industryReporting() {
      return store.getters['admin/reporting/industryReporting'];
    },
    industryLabels() {
      return store.getters['admin/reporting/industryLabels'];
    },
    industriesCount() {
      return store.getters['admin/reporting/industriesCount'];
    },
    chartOptions: function () {
      return {
        colors: [],
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        labels: this.industryLabels,
        plotOptions: {
          pie: {
            size: 200,
            expandOnClick: false,
            donut: {
              size: "88%",
              background: 'transparent',
              labels: {
                show: true,
                name: {
                  show: true,
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  color: "#575761",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "29px",
                  lineHeight: "32px",
                  formatter: function (val) {
                    return val;
                  }
                },
                total: {
                  show: true,
                  label: 'Total Users',
                  color: '#575761',
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "13px",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        // this.industriesCount
        series: this.industriesCount,
        responsive: [
          // {
          //     breakpoint: 768,
          //     options: {
          //         legend: {
          //             position: "bottom",
          //             height:200,
          //             itemMargin: {
          //                 horizontal:0,
          //                 vertical:0
          //         },
          //         }
          //     }
          // },
          // {
          //     breakpoint: 1235,
          //     options: {
          //         legend: {
          //             position: "bottom",
          //             height:220,
          //             itemMargin: {
          //                 horizontal:2,
          //                 vertical:0
          //         },
          //         }
          //     }
          // }
        ]
      };
    }
  },
  created() {
    this.loading = true;
    store.dispatch('admin/reporting/getIndustryReporting').then(data => {
      if (this.industryReporting.length != this.colors.length) {
        this.colors = this.generateColors(this.industryReporting.length);
        localStorage.setItem('FinancialReportingColors', JSON.stringify(this.colors));
      }
      this.updateChart();
    }).finally(() => this.loading = false);
  }
};