var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.loading ? _c('ComponentLoader', {
    attrs: {
      "loading": _vm.loading
    }
  }) : _c('div', {}, [!_vm.industryReporting.length ? _c('div', {
    staticClass: "__no_data"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-11 mt-5 pt-5 text-center"
  }, [_c('h2', {
    staticClass: "__no_data_text"
  }, [_vm._v("No Industries available")])])])]) : _vm._e(), _vm.industryReporting.length ? _c('div', {
    staticClass: "industries_wrapper"
  }, [_c('div', {
    ref: "chart",
    staticClass: "chart__container"
  }, [_c('apexcharts', {
    attrs: {
      "type": "donut",
      "width": "100%",
      "height": "100%",
      "options": _vm.chartOptions,
      "series": _vm.chartOptions.series
    }
  })], 1), _c('div', {
    staticClass: "separator"
  }), _c('div', {
    staticClass: "__industries_list"
  }, [_c('h2', {
    staticClass: "industries"
  }, [_vm._v(" Industries ")]), _c('div', {
    staticClass: "industries-main-list"
  }, [_c('ul', {
    staticClass: "industries_list"
  }, _vm._l(_vm.industryReporting, function (industry) {
    return _c('li', {
      key: industry.id,
      on: {
        "click": function ($event) {
          return _vm.industryDetails(industry);
        }
      }
    }, [_c('div', {
      staticClass: "industry_card"
    }, [_c('div', {
      staticClass: "industry_description"
    }, [_c('div', {
      staticClass: "_color",
      style: `background-color:${_vm.industryColor(industry.industry_name)}`
    }), _c('p', {
      staticClass: "total"
    }, [_vm._v(" " + _vm._s(industry.individual.length) + " ")]), _c('p', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(industry.industry_name) + " ")])]), _c('div', {
      staticClass: "industry_percentage"
    }, [_c('div', {
      staticClass: "percentage"
    }, [_vm._v(" " + _vm._s(_vm.industryPercentage(industry.individual.length)) + " ")]), _c('div', {
      staticClass: "icon"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/arrow_icon.svg"),
        "alt": "img"
      }
    })])])])]);
  }), 0)])])]) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };